import Cart from "models/Cart";
import { PaymentGateway } from "./paymentGateway";
import {EventName, getMerchant_location_id, getPaymentToken, modalError, publish} from "./Request";
import * as Sentry from "@sentry/react";
import { currencies } from "Modules/currencies";
import Swal from "sweetalert2";

export  class PaynoPain extends PaymentGateway{

    set_url_post(url_post): void {
      this.url_post = url_post;
    }
    set_url_ok(url_ok): void {
       this.url_ok = url_ok;

    }
    set_url_ko(url_ko): void {
       this.url_ko = url_ko;
    }

  setHistory = (history) => {
    this.history = history
  }
  
  tokenTicket: string;
    publicToken: string;
    urlBasePost: string;
    namePasarela: string;
    totalAmount: number;
    description: string;
    url_post: string;
    url_ok: string;
    url_ko: string;
    name: string;
    surname: string;
    telephone: string;
    email: string;
    additional_info: any;
    money: string;
    cart: Cart;
    t:any
    environment: any; // dev o prod
    idioma: any;
    history

    constructor(tokenTicket:string, publicToken: string,  totalAmount: number, description: string, name: string, surname: string, telephone: string, email: string, additional_info: any, money: string, cart: Cart,t: any) {
        super();
        this.tokenTicket = tokenTicket;
        this.publicToken = publicToken;
        this.namePasarela = 'PAYNOPAIN';
        this.totalAmount = totalAmount;
        this.description = description;
        this.name = name;
        this.surname = surname;
        this.telephone = telephone;
        this.email = email;
        this.additional_info = additional_info;
        this.money = money;
        this.cart = cart;
        this.t = t;
        this.environment = process.env.REACT_APP_ENVIRONMENT 
        try {
            this.idioma= localStorage.getItem('idioma');
        } catch(e) {
            this.idioma = null
        }
      }

    async pay(): Promise<void> {
           
      let body = await this.mountBody()
      let result = await getPaymentToken(body, this.publicToken, this.namePasarela, this.environment);

      let monedaPago = currencies.filter(c => c.numericCode.toString() === result.currency)

      let url_redirect = ''
      if (result.status === "CREATED" && !result.paid && monedaPago[0] && monedaPago[0].alphabeticCode === this.money) { 
        url_redirect = `https://api.paylands.com/v1${process.env.REACT_APP_ENVIRONMENT === 'dev'? '/sandbox' : ''}/payment/process/${result.token}`
        if(this.idioma) {
          url_redirect = url_redirect + `?lang=${this.idioma}` // Para que el widget de pago aparezca en el idioma si lo hubiese como variable local
        }
        this.history.push(this._at?
          `/${this.publicToken}/main?at=${this._at}`
          :
          `/${this.publicToken}/main`)
        const isIOS = /^iP/.test(navigator.platform) || /^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 4
        if (!isIOS) {
          window.open(url_redirect, '_blank')
        } else {
          window.open(url_redirect, '_self')
        }
        publish(EventName.OnFinish, null)
      } else if(monedaPago[0] && monedaPago[0].alphabeticCode !== this.money) {
        // las monedas no coinciden
        console.log('monedaPago[0] && monedaPago[0].alphabeticCode: ', monedaPago[0] && monedaPago[0].alphabeticCode)
        console.log('this.money: ', this.money)
        Swal.fire({
            icon: 'error',
            title: 'Error - 399',
            text: "El pago no se puede realizar.",
            footer: 'Contacte con la empresa para avisarle del error.' + monedaPago[0] && monedaPago[0].alphabeticCode + '-' + this.money
          })
          Sentry.captureException(new Error(`Paynopain - La moneda del servicio y la de las experiencias no coinciden.`));
      } else {
        let message = '';
        switch (result.error_code) {
          default: 
            message = `${this.t("errorduringPayment")} - `+result.details.replace(" Please, review documentation.", "")
      }

      Sentry.captureException(new Error(`Paynopain - ${message}`));
      modalError(message, null,() => {
        publish(EventName.OnFinish, null)
      });
    }
  }

  async mountBody() {

    const tokenTicket = this.tokenTicket;
    const amount = this.totalAmount*100;
    const additional_info = this.additional_info;
    const url_post = this.url_post;
    const url_ok = this.url_ok;
    const url_ko = this.url_ko;
    const description = this.description;
    const cart = this.cart;
    const publicToken = this.publicToken
      
    const tickets = [...cart.cartTickets, ...cart.cartPacketTickets, ...cart.cartCollaboratorTickets, ...cart.cartCollaboratorPacketTickets]
    let min_expired = 9999999999999; // Una cantidad muy elevada para luego ir comparando y obtener el menor tiempo
    for(let i = 0; i < tickets.length; i++){
        let date_expire_at = new Date(tickets[i].expire_at)
        let ahora = new Date()
        let dif = (date_expire_at.getTime() - ahora.getTime())/1000
        if(dif < min_expired) {
            min_expired = dif;
        }
    }
  
    min_expired = Math.round(min_expired)        
      
    const cartCollaboratorTickets = cart.cartCollaboratorTickets;
    
    if (cartCollaboratorTickets.length>0) {
      // throw new Error('no implementado venta colaboración con pnp')
    }
    
    const cartTickets = cart.cartTickets 
    const cartPacketTickets = cart.cartPacketTickets
    let totalSeller = 0

    let merchants = []

    for(let i = 0; i < cartTickets.length; i++) {// Estos son los tickets de experiencias propias, se sumará el total de todas ellas
      totalSeller = totalSeller + cartTickets[i].ticket.total
    }
    
    const commissionSeller = 0.049
    const totalMarketplace = Math.round(amount * commissionSeller)
    const totalToSeller = totalSeller*100 - totalMarketplace
    if(totalToSeller < 0) {
      Sentry.captureException(new Error("Paynopain - Seller commissions higher than total"));
    }
    let error = false
    let res = await getMerchant_location_id(publicToken, cart.client.id)
    if(res.code === 50 && res.msg === 'Error') error = true

    if(totalToSeller >= 0) {
      let comercio =  {
        merchant_location_id: res,
        amount: amount, // 10000, // amount,
        net_amount: totalToSeller, // 9850, // totalToSeller,
        description: "seller"
      }
      if(!error) merchants.push(comercio)
    }
    
    let marketplace = {
      merchant_location_id: 443,
      amount: 0,
      net_amount: totalMarketplace, // 150, // totalMarketplace,
      description: "ticando-marketplace"
    }

    merchants.push(marketplace)

    return JSON.stringify({
        signature: "",
        reference: `Ticando Order - ${tokenTicket}`,
        amount: amount, // 10000, // amount,
        operative: "AUTHORIZATION",
        additional: additional_info,
        service: cart.uuid,
        secure: true,
        url_post: url_post,
        url_ok: url_ok,
        url_ko: url_ko,
        description: description,
        save_card: false,
        template_uuid: null,
        dcc_template_uuid: null,
        /*extra_data: {
            marketplace : merchants
        },*/
       expires_in: min_expired 
    });
  }
}
import React from "react";
import {useHistory} from "react-router";
import {SearchExperience} from "./SearchExperience";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from "react-i18next";

interface Props {
  publicToken?: string
  at?: string
  isMain?: boolean
  busqueda?: string
  setBusqueda?: any
  setLoaded?: any
  dropdown?
  openCloseDropdown?
  idiomaHandler?
  clientName?
}

export const HeaderView = ({publicToken, at, isMain, busqueda, setBusqueda, setLoaded, dropdown, openCloseDropdown, idiomaHandler, clientName}: Props) => {
  const history = useHistory()
  const [t,] = useTranslation("global");

  return (
    <header className="book-embed-nav with-padding respond-print-hide">
      <div className="ben-right">
        <ul className="ben-left">

         {/* <li className="test-flow-node-breadcrumb" aria-label="Home" title={t("allExperiences")}>
            <button
              className="ben-item ben-cart-button fade-in-up"
              style={{background: 'none', color: '#0a6ece', border: '1px solid #0a6ece', padding: '7px 12px'}}
              onClick={() => {
                at ?
                  history.push(`/${publicToken}/main/?at=${at}`)
                  :
                  history.push(`/${publicToken}/main/`)
              }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   className="bi bi-grid-3x3-gap-fill" viewBox="0 0 16 16">
                <path
                  d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"/>
              </svg>
              <span>
                            {t("allExperiences")}
                            </span>
            </button>
          </li>*/}

          <li className="ben-flyout-wrap" style={{marginRight: 4}}>
            <div>
              <button
                className="ben-item ben-cart-button fade-in-up"
                type="button"
                aria-expanded="false"
                onClick={() => {
                  at ?
                    history.push(`/${publicToken}/cart?at=${at}`)
                    :
                    history.push({pathname: `/${publicToken}/cart`})
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  id="icon-cart"
                  className="icon-cart"
                  width="27" height="23" viewBox="0 0 27 23" style={{fill: 'white'}}
                >
                  <path d="M24.47,16.68H7.12l-3.74-14H.08V.21H5.42l3.74,14H22.31l1.58-8.48H11.25V3.22H26.92Z"/>
                  <path d="M23,20.59a2.2,2.2,0,1,0-2.2,2.2A2.19,2.19,0,0,0,23,20.59Z"/>
                  <path d="M12.19,20.59A2.2,2.2,0,1,0,10,22.79,2.19,2.19,0,0,0,12.19,20.59Z"/>
                </svg>
                <span className="respond-handhelds-hide">{t("cart")}</span>
                <span className="respond-desktop-hide"/>
              </button>
            </div>
          </li>

          {clientName &&
          <li className="ben-flyout-wrap">
            <span className="ben-item ben-translate-button capitalize" style={{
              fontSize: 'medium',
              backgroundColor: '#0A6ECE',
              color: 'white',
              borderLeft: '1px solid',
              marginLeft: 7
            }}
            >
              {clientName}
            </span>
          </li>}

        </ul>
        {
          isMain &&
          <>
            <li style={{textAlign: 'right'}}>
              <SearchExperience
                busqueda={busqueda}
                setBusqueda={setBusqueda}
                setLoaded={setLoaded}
                publicToken={publicToken}
              />
            </li>
            <button className="btn btn-info" style={{marginRight: '110px', backgroundColor: '#0a6ece'}}>
              <FontAwesomeIcon icon={faSearch}/>
            </button>
          </>
        }
      </div>
    </header>
  )
}
